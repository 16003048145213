<template>
  <div
    v-if="loaded"
    class="Admin column"
  >
    <label class="Admin__heading fs-22 fw-med def-lh">Admin</label>

    <!-- NAVIGATION -->
    <div class="Admin__navigation row row--align-center">
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        :to="route.to"
        active-class="Admin__navigation-item--active fc-white"
        class="Admin__navigation-item fc-mid fs-15 capitalize"
        tag="a"
      >
        {{ route.text }}
      </router-link>
    </div>

    <router-view class="Admin__router-view width-100" />
  </div>
</template>

<script>

export default {
  name: 'Admin',

  beforeRouteEnter (to, from, next) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (!user.employee.is_master) {
      next({ name: 'dashboard-bobtail' })
    } else {
      next()
    }
  },

  async created () {
    this.loaded = true
  },

  data () {
    return {
      loaded: false,
      routes: [
        {
          text: 'balances',
          to: { name: 'admin-balances' },
        },
        {
          text: 'debtor upload',
          to: { name: 'admin-debtor-upload' },
        },
        {
          text: 'maintenance',
          to: { name: 'admin-maintenance' },
        },
      ],
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
  },
}
</script>

<style lang="sass" scoped>
.Admin
  $block: &
  background-color: $white
  border: $border
  border-radius: $border-radius
  box-shadow: $shadow-b
  position: relative
  width: 100%

  &__heading
    padding: rem(40px) rem(30px)

  &__navigation
    background-color: rgba($background, .3)
    border-top: $border
    padding: rem(10px) rem(29px)

  &__navigation-item
    margin-right: rem(34px)

    &--active
      background-color: $branding
      border-radius: $border-radius
      padding: rem(2px) rem(10px)

    &:last-child
      margin-right: 0

  &__router-view
    padding: rem(36px) rem(30px) 2.5rem
</style>
