var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "Admin column" },
        [
          _c("label", { staticClass: "Admin__heading fs-22 fw-med def-lh" }, [
            _vm._v("Admin")
          ]),
          _c(
            "div",
            { staticClass: "Admin__navigation row row--align-center" },
            _vm._l(_vm.routes, function(route, index) {
              return _c(
                "router-link",
                {
                  key: index,
                  staticClass: "Admin__navigation-item fc-mid fs-15 capitalize",
                  attrs: {
                    to: route.to,
                    "active-class": "Admin__navigation-item--active fc-white",
                    tag: "a"
                  }
                },
                [_vm._v("\n      " + _vm._s(route.text) + "\n    ")]
              )
            }),
            1
          ),
          _c("router-view", { staticClass: "Admin__router-view width-100" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }